import React, { useEffect, useState } from 'react'
import ResponsiveAppBar from '../../components/header'
import { Box, Container } from '@mui/material'
import './_index.scss'
import { Search } from './search';
import { Footer } from '../../components/footer';
import { SkillsList } from './list';
import { ContactUs } from '../../components/contact-us';
import { Helmet } from 'react-helmet-async';;

const allSkills = {
    Backend: [
        {
            "name": ".NET developers",
            "url": "https://teckable.com/skills/hire-net-developers-verified-experts-from-teckable/"
        },
        {
            "name": ".NET Core developers",
            "url": "https://teckable.com/skills/hire-net-core-developers-with-500-resource-network/"
        },
        {
            "name": ".NET framework developers",
            "url": "https://teckable.com/skills/hire-net-framework-developers-from-500-top-talent/"
        },
        {
            "name": ".NET MAUI developers",
            "url": "https://teckable.com/skills/hire-net-maui-developers-fast/"
        },
        {
            "name": ".NET Sitecore developers",
            "url": "https://teckable.com/skills/hire-net-sitecore-developers-with-experience-and-proven-track-records/"
        },
        {
            "name": "AB Initio developers",
            "url": "https://teckable.com/skills/hire-ab-initio-developers-with-flexible-timeframe/"
        },
        {
            "name": "Ada developers",
            "url": "https://teckable.com/skills/hire-ada-developers-for-your-next-project-today/"
        },
        {
            "name": "Agda developers",
            "url": "https://teckable.com/skills/hire-agda-developers-with-proven-experience/"
        },
        {
            "name": "Ajax developers",
            "url": "https://teckable.com/skills/hire-ajax-developers-in-just-5-days/"
        },
        {
            "name": "Akka developers",
            "url": "https://teckable.com/skills/hire-akka-developers/"
        },
        {
            "name": "Apache Airflow developers",
            "url": "https://teckable.com/skills/hire-apache-airflow-developers-from-remote-resources/"
        },
        {
            "name": "Apache Camel developers",
            "url": "https://teckable.com/skills/hire-apache-camel-developers/"
        },
        {
            "name": "Apache Flink developers",
            "url": "https://teckable.com/skills/hire-apache-flink-developers/"
        },
        {
            "name": "Apache Groovy developers",
            "url": "https://teckable.com/skills/hire-apache-groovy-developers-globally/"
        },
        {
            "name": "Apache Hive developers",
            "url": "https://teckable.com/skills/hire-apache-hive-developers-easily/"
        },
        {
            "name": "Apache HTTP Server developers",
            "url": "https://teckable.com/skills/hire-apache-http-server-developer/"
        },
        {
            "name": "Apache Iceberg developers",
            "url": "https://teckable.com/skills/hire-apache-iceberg-developers/"
        },
        {
            "name": "Apache Kafka developers",
            "url": "https://teckable.com/skills/hire-apache-kafka-developers/"
        },
        {
            "name": "Apache Karaf developers",
            "url": "https://teckable.com/skills/hire-apache-karaf-developers/"
        },
        {
            "name": "Apache NiFi developers",
            "url": "https://teckable.com/skills/hire-apache-nifi-developers/"
        },
        {
            "name": "Apache Spark developers",
            "url": "https://teckable.com/skills/hire-apache-spark-developers/"
        },
        {
            "name": "Apache ZooKeeper developers",
            "url": "https://teckable.com/skills/hire-apache-zookeeper-developers/"
        },
        {
            "name": "App developers",
            "url": "https://teckable.com/skills/hire-app-developers/"
        },
        {
            "name": "AppAuth developers",
            "url": "https://teckable.com/skills/hire-appauth-developers/"
        },
        {
            "name": "Appium developers",
            "url": "https://teckable.com/skills/hire-appium-developers/"
        },
        {
            "name": "ASP.NET developers",
            "url": "https://teckable.com/skills/hire-asp-net-developers/"
        },
        {
            "name": "AWS developers",
            "url": "https://teckable.com/skills/hire-aws-developers/"
        },
        {
            "name": "AWS Amplify developers",
            "url": "https://teckable.com/skills/hire-aws-amplify-developers/"
        },
        {
            "name": "AWS Athena developers",
            "url": "https://teckable.com/skills/hire-aws-athena-developers/"
        },
        {
            "name": "AWS Beanstalk developers",
            "url": "https://teckable.com/skills/hire-aws-beanstalk-developers/"
        },
        {
            "name": "AWS CDK developers",
            "url": "https://teckable.com/skills/hire-aws-cdk-developers/"
        },
        {
            "name": "AWS EC2 developers",
            "url": "https://teckable.com/skills/hire-aws-ec2-developers/"
        },
        {
            "name": "AWS ECR developers",
            "url": "https://teckable.com/skills/hire-aws-ecr-developers/"
        },
        {
            "name": "AWS Kinesis developers",
            "url": "https://teckable.com/skills/hire-aws-kinesis-developers/"
        },
        {
            "name": "AWS Lambda developers",
            "url": "https://teckable.com/skills/hire-aws-lambda-developers/"
        },
        {
            "name": "AWS S3 developers",
            "url": "https://teckable.com/skills/hire-aws-s3-developers/"
        },
        {
            "name": "AWS Snowball developers",
            "url": "https://teckable.com/skills/hire-aws-snowball-developers/"
        },
        {
            "name": "AWS SQS developers",
            "url": "https://teckable.com/skills/hire-aws-sqs-developers/"
        },
        {
            "name": "AWS VPC developers",
            "url": "https://teckable.com/skills/hire-aws-vpc-developers/"
        },
        {
            "name": "Azure developers",
            "url": "https://teckable.com/skills/hire-azure-developers/"
        },
        {
            "name": "Azure Blob storage developers",
            "url": "https://teckable.com/skills/hire-azure-blob-storage-developers/"
        },
        {
            "name": "Azure Cloud developers",
            "url": "https://teckable.com/skills/hire-azure-cloud-developers/"
        },
        {
            "name": "Azure Data Factory developers",
            "url": "https://teckable.com/skills/hire-azure-data-factory-developers/"
        },
        {
            "name": "Azure ML developers",
            "url": "https://teckable.com/skills/hire-azure-ml-developers/"
        },
        {
            "name": "Azure Queue developers",
            "url": "https://teckable.com/skills/hire-azure-queue-developers/"
        },
        {
            "name": "Azure Synapse developers",
            "url": "https://teckable.com/skills/hire-azure-synapse-developers/"
        },
        {
            "name": "Azure Web App developers",
            "url": "https://teckable.com/skills/hire-azure-web-app-developers/"
        },
        {
            "name": "Backbone developers",
            "url": "https://teckable.com/skills/hire-backbone-developers/"
        },
        {
            "name": "Ballerina developers",
            "url": "https://teckable.com/skills/hire-ballerina-developers/"
        },
        {
            "name": "Bash developers",
            "url": "https://teckable.com/skills/hire-bash-developers/"
        },
        {
            "name": "BeautifulSoup developers",
            "url": "https://teckable.com/skills/hire-beautifulsoup-developers/"
        },
        {
            "name": "BigQuery developers",
            "url": "https://teckable.com/skills/hire-bigquery-developers/"
        },
        {
            "name": "Blazor developers",
            "url": "https://teckable.com/skills/hire-blazor-developers/"
        },
        {
            "name": "Business Objects developers",
            "url": "https://teckable.com/skills/hire-business-objects-developers/"
        },
        {
            "name": "C developers",
            "url": "https://teckable.com/skills/hire-c-developers/"
        },
        {
            "name": "C# developers",
            "url": "https://teckable.com/skills/hire-c#-developers/"
        },
        {
            "name": "C++ developers",
            "url": "https://teckable.com/skills/hire-c++-developers/"
        },
        {
            "name": "Caffe developers",
            "url": "https://teckable.com/skills/hire-caffe-developers/"
        },
        {
            "name": "CakePHP developers",
            "url": "https://teckable.com/skills/hire-cakephp-developers/"
        },
        {
            "name": "Calabash developers",
            "url": "https://teckable.com/skills/hire-calabash-developers/"
        },
        {
            "name": "Capistrano developers",
            "url": "https://teckable.com/skills/hire-capistrano-developers/"
        },
        {
            "name": "Cassandra developers",
            "url": "https://teckable.com/skills/hire-cassandra-developers/"
        },
        {
            "name": "Castle.Core developers",
            "url": "https://teckable.com/skills/hire-castle-core-developers/"
        },
        {
            "name": "Celery developers",
            "url": "https://teckable.com/skills/hire-celery-developers/"
        },
        {
            "name": "CentOs developers",
            "url": "https://teckable.com/skills/hire-centos-developers/"
        },
        {
            "name": "Ceylon developers",
            "url": "https://teckable.com/skills/hire-ceylon-developers/"
        },
        {
            "name": "Chart.js developers",
            "url": "https://teckable.com/skills/hire-chart-js-developers/"
        },
        {
            "name": "ChatGPT API developers",
            "url": "https://teckable.com/skills/hire-chatgpt-api-developers/"
        },
        {
            "name": "CircleCI developers",
            "url": "https://teckable.com/skills/hire-circleci-developers/"
        },
        {
            "name": "Clarion developers",
            "url": "https://teckable.com/skills/hire-clarion-developers/"
        },
        {
            "name": "ClickHouse developers",
            "url": "https://teckable.com/skills/hire-clickhouse-developers/"
        },
        {
            "name": "Clipper developers",
            "url": "https://teckable.com/skills/hire-clipper-developers/"
        },
        {
            "name": "Clojure developers",
            "url": "https://teckable.com/skills/hire-clojure-developers/"
        },
        {
            "name": "ClojureScript developers",
            "url": "https://teckable.com/skills/hire-clojurescript-developers/"
        },
        {
            "name": "Cloudera developers",
            "url": "https://teckable.com/skills/hire-cloudera-developers/"
        },
        {
            "name": "CMS developers",
            "url": "https://teckable.com/skills/hire-cms-developers/"
        },
        {
            "name": "COBOL developers",
            "url": "https://teckable.com/skills/hire-cobol-developers/"
        },
        {
            "name": "CocoaPods developers",
            "url": "https://teckable.com/skills/hire-cocoapods-developers/"
        },
        {
            "name": "Cocos2dx developers",
            "url": "https://teckable.com/skills/hire-cocos2dx-developers/"
        },
        {
            "name": "CodeIgniter developers",
            "url": "https://teckable.com/skills/hire-codeigniter-developers/"
        },
        {
            "name": "CoffeeScript developers",
            "url": "https://teckable.com/skills/hire-coffeescript-developers/"
        },
        {
            "name": "Cognos developers",
            "url": "https://teckable.com/skills/hire-cognos-developers/"
        },
        {
            "name": "Convolutional neural network developers",
            "url": "https://teckable.com/skills/hire-convolutional-neural-network-developers/"
        },
        {
            "name": "Cordova developers",
            "url": "https://teckable.com/skills/hire-cordova-developers/"
        },
        {
            "name": "CoreData developers",
            "url": "https://teckable.com/skills/hire-coredata-developers/"
        },
        {
            "name": "Craft CMS developers",
            "url": "https://teckable.com/skills/hire-craft-cms-developers/"
        },
        {
            "name": "CryEngine developers",
            "url": "https://teckable.com/skills/hire-cryengine-developers/"
        },
        {
            "name": "Crystal Report developers",
            "url": "https://teckable.com/skills/hire-crystal-report-developers/"
        },
        {
            "name": "CSV developers",
            "url": "https://teckable.com/skills/hire-csv-developers/"
        },
        {
            "name": "Cucumber developers",
            "url": "https://teckable.com/skills/hire-cucumber-developers/"
        },
        {
            "name": "Cuda developers",
            "url": "https://teckable.com/skills/hire-cuda-developers/"
        },
        {
            "name": "Cypress developers",
            "url": "https://teckable.com/skills/hire-cypress-developers/"
        },
        {
            "name": "D developers",
            "url": "https://teckable.com/skills/hire-d-developers/"
        },
        {
            "name": "Dagger developers",
            "url": "https://teckable.com/skills/hire-dagger-developers/"
        },
        {
            "name": "Dagster developers",
            "url": "https://teckable.com/skills/hire-dagster-developers/"
        },
        {
            "name": "Dapper developers",
            "url": "https://teckable.com/skills/hire-dapper-developers/"
        },
        {
            "name": "Dart developers",
            "url": "https://teckable.com/skills/hire-dart-developers/"
        },
        {
            "name": "Databricks developers",
            "url": "https://teckable.com/skills/hire-databricks-developers/"
        },
        {
            "name": "DataDog developers",
            "url": "https://teckable.com/skills/hire-datadog-developers/"
        },
        {
            "name": "DDD developers",
            "url": "https://teckable.com/skills/hire-ddd-developers/"
        },
        {
            "name": "Delphi developers",
            "url": "https://teckable.com/skills/hire-delphi-developers/"
        },
        {
            "name": "Digital Ocean developers",
            "url": "https://teckable.com/skills/hire-digital-ocean-developers/"
        },
        {
            "name": "Digital strategies developers",
            "url": "https://teckable.com/skills/hire-digital-strategies-developers/"
        },
        {
            "name": "Django developers",
            "url": "https://teckable.com/skills/hire-django-developers/"
        },
        {
            "name": "Dlib developers",
            "url": "https://teckable.com/skills/hire-dlib-developers/"
        },
        {
            "name": "Docker developers",
            "url": "https://teckable.com/skills/hire-docker-developers/"
        },
        {
            "name": "Doctrine ORM developers",
            "url": "https://teckable.com/skills/hire-doctrine-orm-developers/"
        },
        {
            "name": "Dremio developers",
            "url": "https://teckable.com/skills/hire-dremio-developers/"
        },
        {
            "name": "DRF developers",
            "url": "https://teckable.com/skills/hire-drf-developers/"
        },
        {
            "name": "Drupal developers",
            "url": "https://teckable.com/skills/hire-drupal-developers/"
        },
        {
            "name": "DuckDB developers",
            "url": "https://teckable.com/skills/hire-duckdb-developers/"
        },
        {
            "name": "Dynamics AX developers",
            "url": "https://teckable.com/skills/hire-dynamics-ax-developers/"
        },
        {
            "name": "DynamoDB developers",
            "url": "https://teckable.com/skills/hire-dynamodb-developers/"
        },
        {
            "name": "EasyNetQ developers",
            "url": "https://teckable.com/skills/hire-easynetq-developers/"
        },
        {
            "name": "Eclipse developers",
            "url": "https://teckable.com/skills/hire-eclipse-developers/"
        },
        {
            "name": "Eiffel developers",
            "url": "https://teckable.com/skills/hire-eiffel-developers/"
        },
        {
            "name": "ElasticSearch developers",
            "url": "https://teckable.com/skills/hire-elasticsearch-developers/"
        },
        {
            "name": "Electron developers",
            "url": "https://teckable.com/skills/hire-electron-developers/"
        },
        {
            "name": "Elixir developers",
            "url": "https://teckable.com/skills/hire-elixir-developers/"
        },
        {
            "name": "Elm developers",
            "url": "https://teckable.com/skills/hire-elm-developers/"
        },
        {
            "name": "ELT developers",
            "url": "https://teckable.com/skills/hire-elt-developers/"
        },
        {
            "name": "Ember.js developers",
            "url": "https://teckable.com/skills/hire-ember-js-developers/"
        },
        {
            "name": "Entity Framework developers",
            "url": "https://teckable.com/skills/hire-entity-framework-developers/"
        },
        {
            "name": "Erlang developers",
            "url": "https://teckable.com/skills/hire-erlang-developers/"
        },
        {
            "name": "ES5/ES6 developers",
            "url": "https://teckable.com/skills/hire-es5-es6-developers/"
        },
        {
            "name": "ETL developers",
            "url": "https://teckable.com/skills/hire-etl-developers/"
        },
        {
            "name": "Express.js developers",
            "url": "https://teckable.com/skills/hire-express-js-developers/"
        },
        {
            "name": "ExtJS developers",
            "url": "https://teckable.com/skills/hire-extjs-developers/"
        },
        {
            "name": "F# developers",
            "url": "https://teckable.com/skills/hire-f#-developers/"
        },
        {
            "name": "FastAPI developers",
            "url": "https://teckable.com/skills/hire-fastapi-developers/"
        },
        {
            "name": "Fiddler developers",
            "url": "https://teckable.com/skills/hire-fiddler-developers/"
        },
        {
            "name": "Firebase developers",
            "url": "https://teckable.com/skills/hire-firebase-developers/"
        },
        {
            "name": "Flask developers",
            "url": "https://teckable.com/skills/hire-flask-developers/"
        },
        {
            "name": "Formik developers",
            "url": "https://teckable.com/skills/hire-formik-developers/"
        },
        {
            "name": "Forth developers",
            "url": "https://teckable.com/skills/hire-forth-developers/"
        },
        {
            "name": "Fortran developers",
            "url": "https://teckable.com/skills/hire-fortran-developers/"
        },
        {
            "name": "FreeBSD developers",
            "url": "https://teckable.com/skills/hire-freebsd-developers/"
        },
        {
            "name": "Freelance developers",
            "url": "https://teckable.com/skills/hire-freelance-developers/"
        },
        {
            "name": "Gatsby.js developers",
            "url": "https://teckable.com/skills/hire-gatsby-js-developers/"
        },
        {
            "name": "Ghost Inspector developers",
            "url": "https://teckable.com/skills/hire-ghost-inspector-developers/"
        },
        {
            "name": "Glide developers",
            "url": "https://teckable.com/skills/hire-glide-developers/"
        },
        {
            "name": "GNU Octave developers",
            "url": "https://teckable.com/skills/hire-gnu-octave-developers/"
        },
        {
            "name": "Godot Engine developers",
            "url": "https://teckable.com/skills/hire-godot-engine-developers/"
        },
        {
            "name": "Golang developers",
            "url": "https://teckable.com/skills/hire-golang-developers/"
        },
        {
            "name": "Google Cloud developers",
            "url": "https://teckable.com/skills/hire-google-cloud-developers/"
        },
        {
            "name": "Gorm developers",
            "url": "https://teckable.com/skills/hire-gorm-developers/"
        },
        {
            "name": "Gosu developers",
            "url": "https://teckable.com/skills/hire-gosu-developers/"
        },
        {
            "name": "Gradle developers",
            "url": "https://teckable.com/skills/hire-gradle-developers/"
        },
        {
            "name": "Grafana developers",
            "url": "https://teckable.com/skills/hire-grafana-developers/"
        },
        {
            "name": "Graphene developers",
            "url": "https://teckable.com/skills/hire-graphene-developers/"
        },
        {
            "name": "GraphQL developers",
            "url": "https://teckable.com/skills/hire-graphql-developers/"
        },
        {
            "name": "gRPC developers",
            "url": "https://teckable.com/skills/hire-grpc-developers/"
        },
        {
            "name": "Grunt.js developers",
            "url": "https://teckable.com/skills/hire-grunt-js-developers/"
        },
        {
            "name": "Gulp developers",
            "url": "https://teckable.com/skills/hire-gulp-developers/"
        },
        {
            "name": "Hack developers",
            "url": "https://teckable.com/skills/hire-hack-developers/"
        },
        {
            "name": "Hadoop developers",
            "url": "https://teckable.com/skills/hire-hadoop-developers/"
        },
        {
            "name": "Hamcrest developers",
            "url": "https://teckable.com/skills/hire-hamcrest-developers/"
        },
        {
            "name": "Hangfire developers",
            "url": "https://teckable.com/skills/hire-hangfire-developers/"
        },
        {
            "name": "HAProxy developers",
            "url": "https://teckable.com/skills/hire-haproxy-developers/"
        },
        {
            "name": "HashiCorp Cloud Platform developers",
            "url": "https://teckable.com/skills/hire-hashicorp-cloud-platform-developers/"
        },
        {
            "name": "Haskell developers",
            "url": "https://teckable.com/skills/hire-haskell-developers/"
        },
        {
            "name": "Hasura developers",
            "url": "https://teckable.com/skills/hire-hasura-developers/"
        },
        {
            "name": "Haxe developers",
            "url": "https://teckable.com/skills/hire-haxe-developers/"
        },
        {
            "name": "HBase developers",
            "url": "https://teckable.com/skills/hire-hbase-developers/"
        },
        {
            "name": "HCP (Hitachi Content Platform) developers",
            "url": "https://teckable.com/skills/hire-hcp-(hitachi-content-platform)-developers/"
        },
        {
            "name": "Heroku developers",
            "url": "https://teckable.com/skills/hire-heroku-developers/"
        },
        {
            "name": "Hibernate developers",
            "url": "https://teckable.com/skills/hire-hibernate-developers/"
        },
        {
            "name": "HLSL developers",
            "url": "https://teckable.com/skills/hire-hlsl-developers/"
        },
        {
            "name": "Idris developers",
            "url": "https://teckable.com/skills/hire-idris-developers/"
        },
        {
            "name": "Inertia.js developers",
            "url": "https://teckable.com/skills/hire-inertia-js-developers/"
        },
        {
            "name": "InfluxDB developers",
            "url": "https://teckable.com/skills/hire-influxdb-developers/"
        },
        {
            "name": "Informatica developers",
            "url": "https://teckable.com/skills/hire-informatica-developers/"
        },
        {
            "name": "IntelliJ developers",
            "url": "https://teckable.com/skills/hire-intellij-developers/"
        },
        {
            "name": "Ioke developers",
            "url": "https://teckable.com/skills/hire-ioke-developers/"
        },
        {
            "name": "Ionic developers",
            "url": "https://teckable.com/skills/hire-ionic-developers/"
        },
        {
            "name": "Ionic 2 developers",
            "url": "https://teckable.com/skills/hire-ionic-2-developers/"
        },
        {
            "name": "Jakarta developers",
            "url": "https://teckable.com/skills/hire-jakarta-developers/"
        },
        {
            "name": "Jasmine developers",
            "url": "https://teckable.com/skills/hire-jasmine-developers/"
        },
        {
            "name": "Java developers",
            "url": "https://teckable.com/skills/hire-java-developers/"
        },
        {
            "name": "Jenkins developers",
            "url": "https://teckable.com/skills/hire-jenkins-developers/"
        },
        {
            "name": "Jest developers",
            "url": "https://teckable.com/skills/hire-jest-developers/"
        },
        {
            "name": "Joomla developers",
            "url": "https://teckable.com/skills/hire-joomla-developers/"
        },
        {
            "name": "jQuery developers",
            "url": "https://teckable.com/skills/hire-jquery-developers/"
        },
        {
            "name": "Jsonnet developers",
            "url": "https://teckable.com/skills/hire-jsonnet-developers/"
        },
        {
            "name": "Julia developers",
            "url": "https://teckable.com/skills/hire-julia-developers/"
        },
        {
            "name": "Karate developers",
            "url": "https://teckable.com/skills/hire-karate-developers/"
        },
        {
            "name": "Katalon Studio developers",
            "url": "https://teckable.com/skills/hire-katalon-studio-developers/"
        },
        {
            "name": "Keras developers",
            "url": "https://teckable.com/skills/hire-keras-developers/"
        },
        {
            "name": "Keycloak developers",
            "url": "https://teckable.com/skills/hire-keycloak-developers/"
        },
        {
            "name": "Kibana developers",
            "url": "https://teckable.com/skills/hire-kibana-developers/"
        },
        {
            "name": "KingFisher developers",
            "url": "https://teckable.com/skills/hire-kingfisher-developers/"
        },
        {
            "name": "Kivy developers",
            "url": "https://teckable.com/skills/hire-kivy-developers/"
        },
        {
            "name": "Klarna API developers",
            "url": "https://teckable.com/skills/hire-klarna-api-developers/"
        },
        {
            "name": "Knockout.js developers",
            "url": "https://teckable.com/skills/hire-knockout-js-developers/"
        },
        {
            "name": "Koa developers",
            "url": "https://teckable.com/skills/hire-koa-developers/"
        },
        {
            "name": "Kotlin developers",
            "url": "https://teckable.com/skills/hire-kotlin-developers/"
        },
        {
            "name": "Ktor developers",
            "url": "https://teckable.com/skills/hire-ktor-developers/"
        },
        {
            "name": "Kubeflow developers",
            "url": "https://teckable.com/skills/hire-kubeflow-developers/"
        },
        {
            "name": "Kubernetes developers",
            "url": "https://teckable.com/skills/hire-kubernetes-developers/"
        },
        {
            "name": "LAMP developers",
            "url": "https://teckable.com/skills/hire-lamp-developers/"
        },
        {
            "name": "LangChain developers",
            "url": "https://teckable.com/skills/hire-langchain-developers/"
        },
        {
            "name": "Laravel developers",
            "url": "https://teckable.com/skills/hire-laravel-developers/"
        },
        {
            "name": "LaTeX developers",
            "url": "https://teckable.com/skills/hire-latex-developers/"
        },
        {
            "name": "Lerna developers",
            "url": "https://teckable.com/skills/hire-lerna-developers/"
        },
        {
            "name": "Less CSS developers",
            "url": "https://teckable.com/skills/hire-less-css-developers/"
        },
        {
            "name": "Limbo developers",
            "url": "https://teckable.com/skills/hire-limbo-developers/"
        },
        {
            "name": "Linux developers",
            "url": "https://teckable.com/skills/hire-linux-developers/"
        },
        {
            "name": "Lisp developers",
            "url": "https://teckable.com/skills/hire-lisp-developers/"
        },
        {
            "name": "LIT Element developers",
            "url": "https://teckable.com/skills/hire-lit-element-developers/"
        },
        {
            "name": "Livewire developers",
            "url": "https://teckable.com/skills/hire-livewire-developers/"
        },
        {
            "name": "Logstash developers",
            "url": "https://teckable.com/skills/hire-logstash-developers/"
        },
        {
            "name": "Lua developers",
            "url": "https://teckable.com/skills/hire-lua-developers/"
        },
        {
            "name": "Maple developers",
            "url": "https://teckable.com/skills/hire-maple-developers/"
        },
        {
            "name": "MariaDB developers",
            "url": "https://teckable.com/skills/hire-mariadb-developers/"
        },
        {
            "name": "Matlab developers",
            "url": "https://teckable.com/skills/hire-matlab-developers/"
        },
        {
            "name": "Matplotlib developers",
            "url": "https://teckable.com/skills/hire-matplotlib-developers/"
        },
        {
            "name": "Maven developers",
            "url": "https://teckable.com/skills/hire-maven-developers/"
        },
        {
            "name": "Mercury developers",
            "url": "https://teckable.com/skills/hire-mercury-developers/"
        },
        {
            "name": "Meteor.js developers",
            "url": "https://teckable.com/skills/hire-meteor-js-developers/"
        },
        {
            "name": "Microservices developers",
            "url": "https://teckable.com/skills/hire-microservices-developers/"
        },
        {
            "name": "Microsoft Silverlight developers",
            "url": "https://teckable.com/skills/hire-microsoft-silverlight-developers/"
        },
        {
            "name": "Miva Script developers",
            "url": "https://teckable.com/skills/hire-miva-script-developers/"
        },
        {
            "name": "MLOps developers",
            "url": "https://teckable.com/skills/hire-mlops-developers/"
        },
        {
            "name": "MobX developers",
            "url": "https://teckable.com/skills/hire-mobx-developers/"
        },
        {
            "name": "Mocha developers",
            "url": "https://teckable.com/skills/hire-mocha-developers/"
        },
        {
            "name": "Mockito developers",
            "url": "https://teckable.com/skills/hire-mockito-developers/"
        },
        {
            "name": "Mojo developers",
            "url": "https://teckable.com/skills/hire-mojo-developers/"
        },
        {
            "name": "Monaca developers",
            "url": "https://teckable.com/skills/hire-monaca-developers/"
        },
        {
            "name": "MongoDB developers",
            "url": "https://teckable.com/skills/hire-mongodb-developers/"
        },
        {
            "name": "Moodle developers",
            "url": "https://teckable.com/skills/hire-moodle-developers/"
        },
        {
            "name": "MQTT developers",
            "url": "https://teckable.com/skills/hire-mqtt-developers/"
        },
        {
            "name": "MSSQL developers",
            "url": "https://teckable.com/skills/hire-mssql-developers/"
        },
        {
            "name": "Mustache developers",
            "url": "https://teckable.com/skills/hire-mustache-developers/"
        },
        {
            "name": "MVVM designers",
            "url": "https://teckable.com/skills/hire-mvvm-designers/"
        },
        {
            "name": "MySQL developers",
            "url": "https://teckable.com/skills/hire-mysql-developers/"
        },
        {
            "name": "NativeScript developers",
            "url": "https://teckable.com/skills/hire-nativescript-developers/"
        },
        {
            "name": "NATS developers",
            "url": "https://teckable.com/skills/hire-nats-developers/"
        },
        {
            "name": "Neko developers",
            "url": "https://teckable.com/skills/hire-neko-developers/"
        },
        {
            "name": "Nemerle developers",
            "url": "https://teckable.com/skills/hire-nemerle-developers/"
        },
        {
            "name": "Neo4j developers",
            "url": "https://teckable.com/skills/hire-neo4j-developers/"
        },
        {
            "name": "Nest.js developers",
            "url": "https://teckable.com/skills/hire-nest-js-developers/"
        },
        {
            "name": "Newspeak developers",
            "url": "https://teckable.com/skills/hire-newspeak-developers/"
        },
        {
            "name": "Next.js developers",
            "url": "https://teckable.com/skills/hire-next-js-developers/"
        },
        {
            "name": "NGINX developers",
            "url": "https://teckable.com/skills/hire-nginx-developers/"
        },
        {
            "name": "NgRx developers",
            "url": "https://teckable.com/skills/hire-ngrx-developers/"
        },
        {
            "name": "NGXS developers",
            "url": "https://teckable.com/skills/hire-ngxs-developers/"
        },
        {
            "name": "Nim developers",
            "url": "https://teckable.com/skills/hire-nim-developers/"
        },
        {
            "name": "Ninja developers",
            "url": "https://teckable.com/skills/hire-ninja-developers/"
        },
        {
            "name": "NLP developers",
            "url": "https://teckable.com/skills/hire-nlp-developers/"
        },
        {
            "name": "NMock developers",
            "url": "https://teckable.com/skills/hire-nmock-developers/"
        },
        {
            "name": "Node.js developers",
            "url": "https://teckable.com/skills/hire-node-js-developers/"
        },
        {
            "name": "NumPy developers",
            "url": "https://teckable.com/skills/hire-numpy-developers/"
        },
        {
            "name": "NUnit developers",
            "url": "https://teckable.com/skills/hire-nunit-developers/"
        },
        {
            "name": "Nuxt.js developers",
            "url": "https://teckable.com/skills/hire-nuxt-js-developers/"
        },
        {
            "name": "OAuth2 developers",
            "url": "https://teckable.com/skills/hire-oauth2-developers/"
        }
    ],

    // Backend: [
    //     ".NET developers", ".NET Core developers", ".NET framework developers", ".NET MAUI developers",
    //     ".NET Sitecore developers", "AB Initio developers", "Ada developers", "Agda developers",
    //     "Ajax developers", "Akka developers", "Apache Airflow developers", "Apache Camel developers",
    //     "Apache Flink developers", "Apache Groovy developers", "Apache Hive developers",
    //     "Apache HTTP Server developers", "Apache Iceberg developers", "Apache Kafka developers",
    //     "Apache Karaf developers", "Apache NiFi developers", "Apache Spark developers",
    //     "Apache ZooKeeper developers", "App developers", "AppAuth developers", "Appium developers",
    //     "ASP.NET developers", "AWS developers", "AWS Amplify developers", "AWS Athena developers",
    //     "AWS Beanstalk developers", "AWS CDK developers", "AWS EC2 developers", "AWS ECR developers",
    //     "AWS Kinesis developers", "AWS Lambda developers", "AWS S3 developers", "AWS Snowball developers",
    //     "AWS SQS developers", "AWS VPC developers", "Azure developers", "Azure Blob storage developers",
    //     "Azure Cloud developers", "Azure Data Factory developers", "Azure ML developers",
    //     "Azure Queue developers", "Azure Synapse developers", "Azure Web App developers",
    //     "Backbone developers", "Ballerina developers", "Bash developers", "BeautifulSoup developers",
    //     "BigQuery developers", "Blazor developers", "Business Objects developers", "C developers",
    //     "C# developers", "C++ developers", "Caffe developers", "CakePHP developers", "Calabash developers",
    //     "Capistrano developers", "Cassandra developers", "Castle.Core developers", "Celery developers",
    //     "CentOs developers", "Ceylon developers", "Chart.js developers", "ChatGPT API developers",
    //     "CircleCI developers", "Clarion developers", "ClickHouse developers", "Clipper developers",
    //     "Clojure developers", "ClojureScript developers", "Cloudera developers", "CMS developers",
    //     "COBOL developers", "CocoaPods developers", "Cocos2dx developers", "CodeIgniter developers",
    //     "CoffeeScript developers", "Cognos developers", "Convolutional neural network developers",
    //     "Cordova developers", "CoreData developers", "Craft CMS developers", "CryEngine developers",
    //     "Crystal Report developers", "CSV developers", "Cucumber developers", "Cuda developers",
    //     "Cypress developers", "D developers", "Dagger developers", "Dagster developers", "Dapper developers",
    //     "Dart developers", "Databricks developers", "DataDog developers", "DDD developers", "Delphi developers",
    //     "Digital Ocean developers", "Digital strategies developers", "Django developers", "Dlib developers",
    //     "Docker developers", "Doctrine ORM developers", "Dremio developers", "DRF developers",
    //     "Drupal developers", "DuckDB developers", "Dynamics AX developers", "DynamoDB developers",
    //     "EasyNetQ developers", "Eclipse developers", "Eiffel developers", "ElasticSearch developers",
    //     "Electron developers", "Elixir developers", "Elm developers", "ELT developers", "Ember.js developers",
    //     "Entity Framework developers", "Erlang developers", "ES5/ES6 developers", "ETL developers",
    //     "Express.js developers", "ExtJS developers", "F# developers", "FastAPI developers", "Fiddler developers",
    //     "Firebase developers", "Flask developers", "Formik developers", "Forth developers", "Fortran developers",
    //     "FreeBSD developers", "Freelance developers", "Gatsby.js developers", "Ghost Inspector developers",
    //     "Glide developers", "GNU Octave developers", "Godot Engine developers", "Golang developers",
    //     "Google Cloud developers", "Gorm developers", "Gosu developers", "Gradle developers", "Grafana developers",
    //     "Graphene developers", "GraphQL developers", "gRPC developers", "Grunt.js developers", "Gulp developers",
    //     "Hack developers", "Hadoop developers", "Hamcrest developers", "Hangfire developers", "HAProxy developers",
    //     "HashiCorp Cloud Platform developers", "Haskell developers", "Hasura developers", "Haxe developers",
    //     "HBase developers", "HCP (Hitachi Content Platform) developers", "Heroku developers", "Hibernate developers",
    //     "HLSL developers", "Idris developers", "Inertia.js developers", "InfluxDB developers", "Informatica developers",
    //     "IntelliJ developers", "Ioke developers", "Ionic developers", "Ionic 2 developers", "Jakarta developers",
    //     "Jasmine developers", "Java developers", "Jenkins developers", "Jest developers", "Joomla developers",
    //     "jQuery developers", "Jsonnet developers", "Julia developers", "Karate developers", "Katalon Studio developers",
    //     "Keras developers", "Keycloak developers", "Kibana developers", "KingFisher developers", "Kivy developers",
    //     "Klarna API developers", "Knockout.js developers", "Koa developers", "Kotlin developers", "Ktor developers",
    //     "Kubeflow developers", "Kubernetes developers", "LAMP developers", "LangChain developers", "Laravel developers",
    //     "LaTeX developers", "Lerna developers", "Less CSS developers", "Limbo developers", "Linux developers",
    //     "Lisp developers", "LIT Element developers", "Livewire developers", "Logstash developers", "Lua developers",
    //     "Maple developers", "MariaDB developers", "Matlab developers", "Matplotlib developers", "Maven developers",
    //     "Mercury developers", "Meteor.js developers", "Microservices developers", "Microsoft Silverlight developers",
    //     "Miva Script developers", "MLOps developers", "MobX developers", "Mocha developers", "Mockito developers",
    //     "Mojo developers", "Monaca developers", "MongoDB developers", "Moodle developers", "MQTT developers",
    //     "MSSQL developers", "Mustache developers", "MVVM designers", "MySQL developers", "NativeScript developers",
    //     "NATS developers", "Neko developers", "Nemerle developers", "Neo4j developers", "Nest.js developers",
    //     "Newspeak developers", "Next.js developers", "NGINX developers", "NgRx developers", "NGXS developers",
    //     "Nim developers", "Ninja developers", "NLP developers", "NMock developers", "Node.js developers",
    //     "NumPy developers", "NUnit developers", "Nuxt.js developers", "OAuth2 developers"
    // ],
    Frontend: [
        {
            "name": "Alamofire developers",
            "url": "https://teckable.com/skills/hire-alamofire-developers/"
        },
        {
            "name": "Algolia Search developers",
            "url": "https://teckable.com/skills/hire-algolia-search-developers/"
        },
        {
            "name": "Alpine.js developers",
            "url": "https://teckable.com/skills/hire-alpine-js-developers/"
        },
        {
            "name": "Angular developers",
            "url": "https://teckable.com/skills/hire-angular-developers/"
        },
        {
            "name": "Angular.js developers",
            "url": "https://teckable.com/skills/hire-angular-js-developers/"
        },
        {
            "name": "Backbone developers",
            "url": "https://teckable.com/skills/hire-backbone-developers/"
        },
        {
            "name": "Blazor developers",
            "url": "https://teckable.com/skills/hire-blazor-developers/"
        },
        {
            "name": "Bootstrap developers",
            "url": "https://teckable.com/skills/hire-bootstrap-developers/"
        },
        {
            "name": "Chart.js developers",
            "url": "https://teckable.com/skills/hire-chart-js-developers/"
        },
        {
            "name": "Clojure developers",
            "url": "https://teckable.com/skills/hire-clojure-developers/"
        },
        {
            "name": "ClojureScript developers",
            "url": "https://teckable.com/skills/hire-clojurescript-developers/"
        },
        {
            "name": "CocoaPods developers",
            "url": "https://teckable.com/skills/hire-cocoapods-developers/"
        },
        {
            "name": "Cocos2dx developers",
            "url": "https://teckable.com/skills/hire-cocos2dx-developers/"
        },
        {
            "name": "CodeIgniter developers",
            "url": "https://teckable.com/skills/hire-codeigniter-developers/"
        },
        {
            "name": "CoffeeScript developers",
            "url": "https://teckable.com/skills/hire-coffeescript-developers/"
        },
        {
            "name": "CSS developers",
            "url": "https://teckable.com/skills/hire-css-developers/"
        },
        {
            "name": "Elm developers",
            "url": "https://teckable.com/skills/hire-elm-developers/"
        },
        {
            "name": "Ember.js developers",
            "url": "https://teckable.com/skills/hire-ember-js-developers/"
        },
        {
            "name": "ES5/ES6 developers",
            "url": "https://teckable.com/skills/hire-es5-es6-developers/"
        },
        {
            "name": "ExtJS developers",
            "url": "https://teckable.com/skills/hire-extjs-developers/"
        },
        {
            "name": "Fiddler developers",
            "url": "https://teckable.com/skills/hire-fiddler-developers/"
        },
        {
            "name": "Formik developers",
            "url": "https://teckable.com/skills/hire-formik-developers/"
        },
        {
            "name": "Gatsby.js developers",
            "url": "https://teckable.com/skills/hire-gatsby-js-developers/"
        },
        {
            "name": "Gulp developers",
            "url": "https://teckable.com/skills/hire-gulp-developers/"
        },
        {
            "name": "HTML developers",
            "url": "https://teckable.com/skills/hire-html-developers/"
        },
        {
            "name": "Ionic developers",
            "url": "https://teckable.com/skills/hire-ionic-developers/"
        },
        {
            "name": "Ionic 2 developers",
            "url": "https://teckable.com/skills/hire-ionic-2-developers/"
        },
        {
            "name": "jQuery developers",
            "url": "https://teckable.com/skills/hire-jquery-developers/"
        },
        {
            "name": "Jsonnet developers",
            "url": "https://teckable.com/skills/hire-jsonnet-developers/"
        },
        {
            "name": "Knockout.js developers",
            "url": "https://teckable.com/skills/hire-knockout-js-developers/"
        },
        {
            "name": "Kotlin developers",
            "url": "https://teckable.com/skills/hire-kotlin-developers/"
        },
        {
            "name": "Less CSS developers",
            "url": "https://teckable.com/skills/hire-less-css-developers/"
        },
        {
            "name": "LIT Element developers",
            "url": "https://teckable.com/skills/hire-lit-element-developers/"
        },
        {
            "name": "Material-UI developers",
            "url": "https://teckable.com/skills/hire-material-ui-developers/"
        },
        {
            "name": "Next.js developers",
            "url": "https://teckable.com/skills/hire-next-js-developers/"
        },
        {
            "name": "React.js developers",
            "url": "https://teckable.com/skills/hire-react-js-developers/"
        },
        {
            "name": "Redux developers",
            "url": "https://teckable.com/skills/hire-redux-developers/"
        },
        {
            "name": "Sass developers",
            "url": "https://teckable.com/skills/hire-sass-developers/"
        },
        {
            "name": "SCSS developers",
            "url": "https://teckable.com/skills/hire-scss-developers/"
        },
        {
            "name": "TypeScript developers",
            "url": "https://teckable.com/skills/hire-typescript-developers/"
        },
        {
            "name": "Vue.js developers",
            "url": "https://teckable.com/skills/hire-vue-js-developers/"
        }
    ],

    Fullstack: [
        {
            "name": "ASP.NET developers",
            "url": "https://teckable.com/skills/hire-asp-net-developers/"
        },
        {
            "name": "Backbone developers",
            "url": "https://teckable.com/skills/hire-backbone-developers/"
        },
        {
            "name": "Blazor developers",
            "url": "https://teckable.com/skills/hire-blazor-developers/"
        },
        {
            "name": "Fullstack developers",
            "url": "https://teckable.com/skills/hire-fullstack-developers/"
        },
        {
            "name": "Gatsby.js developers",
            "url": "https://teckable.com/skills/hire-gatsby-js-developers/"
        },
        {
            "name": "Meteor.js developers",
            "url": "https://teckable.com/skills/hire-meteor-js-developers/"
        },
        {
            "name": "Next.js developers",
            "url": "https://teckable.com/skills/hire-next-js-developers/"
        },
        {
            "name": "Nuxt.js developers",
            "url": "https://teckable.com/skills/hire-nuxt-js-developers/"
        }
    ],

    Mobile: [
        {
            "name": "Android developers",
            "url": "https://teckable.com/skills/hire-android-developers/"
        },
        {
            "name": "Cordova developers",
            "url": "https://teckable.com/skills/hire-cordova-developers/"
        },
        {
            "name": "Flutter developers",
            "url": "https://teckable.com/skills/hire-flutter-developers/"
        },
        {
            "name": "iOS developers",
            "url": "https://teckable.com/skills/hire-ios-developers/"
        },
        {
            "name": "Ionic developers",
            "url": "https://teckable.com/skills/hire-ionic-developers/"
        },
        {
            "name": "Ionic 2 developers",
            "url": "https://teckable.com/skills/hire-ionic-2-developers/"
        },
        {
            "name": "React Native developers",
            "url": "https://teckable.com/skills/hire-react-native-developers/"
        },
        {
            "name": "Swift developers",
            "url": "https://teckable.com/skills/hire-swift-developers/"
        }
    ],
    GameDevelopment: [
        {
            "name": "Cocos2dx developers",
            "url": "https://teckable.com/skills/hire-cocos2dx-developers/"
        },
        {
            "name": "CryEngine developers",
            "url": "https://teckable.com/skills/hire-cryengine-developers/"
        },
        {
            "name": "Godot Engine developers",
            "url": "https://teckable.com/skills/hire-godot-engine-developers/"
        },
        {
            "name": "Unity developers",
            "url": "https://teckable.com/skills/hire-unity-developers/"
        },
        {
            "name": "Unreal Engine developers",
            "url": "https://teckable.com/skills/hire-unreal-engine-developers/"
        }
    ],
    DataEngineering: [
        {
            "name": "Apache Hive developers",
            "url": "https://teckable.com/skills/hire-apache-hive-developers/"
        },
        {
            "name": "BigQuery developers",
            "url": "https://teckable.com/skills/hire-bigquery-developers/"
        },
        {
            "name": "Databricks developers",
            "url": "https://teckable.com/skills/hire-databricks-developers/"
        },
        {
            "name": "Dremio developers",
            "url": "https://teckable.com/skills/hire-dremio-developers/"
        },
        {
            "name": "DuckDB developers",
            "url": "https://teckable.com/skills/hire-duckdb-developers/"
        },
        {
            "name": "ETL developers",
            "url": "https://teckable.com/skills/hire-etl-developers/"
        },
        {
            "name": "Hadoop developers",
            "url": "https://teckable.com/skills/hire-hadoop-developers/"
        },
        {
            "name": "Kafka developers",
            "url": "https://teckable.com/skills/hire-kafka-developers/"
        },
        {
            "name": "Pandas developers",
            "url": "https://teckable.com/skills/hire-pandas-developers/"
        },
        {
            "name": "Snowflake developers",
            "url": "https://teckable.com/skills/hire-snowflake-developers/"
        },
        {
            "name": "Spark developers",
            "url": "https://teckable.com/skills/hire-spark-developers/"
        }
    ],
    DataScience: [
        {
            "name": "Data analysts",
            "url": "https://teckable.com/skills/hire-data-analysts/"
        },
        {
            "name": "Data engineers",
            "url": "https://teckable.com/skills/hire-data-engineers/"
        },
        {
            "name": "Data scientists",
            "url": "https://teckable.com/skills/hire-data-scientists/"
        },
        {
            "name": "HBase developers",
            "url": "https://teckable.com/skills/hire-hbase-developers/"
        },
        {
            "name": "Julia developers",
            "url": "https://teckable.com/skills/hire-julia-developers/"
        },
        {
            "name": "Machine Learning developers",
            "url": "https://teckable.com/skills/hire-machine-learning-developers/"
        },
        {
            "name": "Matplotlib developers",
            "url": "https://teckable.com/skills/hire-matplotlib-developers/"
        },
        {
            "name": "NumPy developers",
            "url": "https://teckable.com/skills/hire-numpy-developers/"
        },
        {
            "name": "Pandas developers",
            "url": "https://teckable.com/skills/hire-pandas-developers/"
        },
        {
            "name": "R developers",
            "url": "https://teckable.com/skills/hire-r-developers/"
        },
        {
            "name": "SciPy developers",
            "url": "https://teckable.com/skills/hire-scipy-developers/"
        },
        {
            "name": "TensorFlow developers",
            "url": "https://teckable.com/skills/hire-tensorflow-developers/"
        }
    ],
    MachineLearning: [
        {
            "name": "Data scientists",
            "url": "https://teckable.com/skills/hire-data-scientists/"
        },
        {
            "name": "Machine Learning developers",
            "url": "https://teckable.com/skills/hire-machine-learning-developers/"
        },
        {
            "name": "PyTorch developers",
            "url": "https://teckable.com/skills/hire-pytorch-developers/"
        },
        {
            "name": "Scikit-learn developers",
            "url": "https://teckable.com/skills/hire-scikit-learn-developers/"
        },
        {
            "name": "TensorFlow developers",
            "url": "https://teckable.com/skills/hire-tensorflow-developers/"
        }
    ],
    BusinessIntelligence: [
        {
            "name": "Business analysts",
            "url": "https://teckable.com/skills/hire-business-analysts/"
        },
        {
            "name": "Cognos developers",
            "url": "https://teckable.com/skills/hire-cognos-developers/"
        },
        {
            "name": "Microsoft Power BI developers",
            "url": "https://teckable.com/skills/hire-microsoft-power-bi-developers/"
        },
        {
            "name": "Tableau developers",
            "url": "https://teckable.com/skills/hire-tableau-developers/"
        }
    ],
    DevOps: [
        {
            "name": "Ansible developers",
            "url": "https://teckable.com/skills/hire-ansible-developers/"
        },
        {
            "name": "CircleCI developers",
            "url": "https://teckable.com/skills/hire-circleci-developers/"
        },
        {
            "name": "Docker developers",
            "url": "https://teckable.com/skills/hire-docker-developers/"
        },
        {
            "name": "GitHub Actions developers",
            "url": "https://teckable.com/skills/hire-github-actions-developers/"
        },
        {
            "name": "Jenkins developers",
            "url": "https://teckable.com/skills/hire-jenkins-developers/"
        },
        {
            "name": "Kubernetes developers",
            "url": "https://teckable.com/skills/hire-kubernetes-developers/"
        },
        {
            "name": "Terraform developers",
            "url": "https://teckable.com/skills/hire-terraform-developers/"
        },
        {
            "name": "Travis CI developers",
            "url": "https://teckable.com/skills/hire-travis-ci-developers/"
        }
    ],
    QA: [
        {
            "name": "Appium developers",
            "url": "https://teckable.com/skills/hire-appium-developers/"
        },
        {
            "name": "Cypress developers",
            "url": "https://teckable.com/skills/hire-cypress-developers/"
        },
        {
            "name": "JUnit developers",
            "url": "https://teckable.com/skills/hire-junit-developers/"
        },
        {
            "name": "Mocha developers",
            "url": "https://teckable.com/skills/hire-mocha-developers/"
        },
        {
            "name": "Selenium developers",
            "url": "https://teckable.com/skills/hire-selenium-developers/"
        },
        {
            "name": "TestNG developers",
            "url": "https://teckable.com/skills/hire-testng-developers/"
        }
    ],
    Design: [
        {
            "name": "Adobe Creative Suite designers",
            "url": "https://teckable.com/skills/hire-adobe-creative-suite-designers/"
        },
        {
            "name": "Figma designers",
            "url": "https://teckable.com/skills/hire-figma-designers/"
        },
        {
            "name": "Sketch designers",
            "url": "https://teckable.com/skills/hire-sketch-designers/"
        },
        {
            "name": "UX designers",
            "url": "https://teckable.com/skills/hire-ux-designers/"
        },
        {
            "name": "UI designers",
            "url": "https://teckable.com/skills/hire-ui-designers/"
        }
    ],
    CloudComputing: [
        {
            "name": "AWS developers",
            "url": "https://teckable.com/skills/hire-aws-developers/"
        },
        {
            "name": "Azure developers",
            "url": "https://teckable.com/skills/hire-azure-developers/"
        },
        {
            "name": "Google Cloud developers",
            "url": "https://teckable.com/skills/hire-google-cloud-developers/"
        }
    ]
};

const Skills = () => {
    const [roles, setRoles] = useState([])
    const [letter, setLetter] = useState("")
    const [skills, setSkills] = useState([])

    useEffect(() => {
        let skillsArray = [];
        if (roles.length && letter && letter !== "#") {
            skillsArray = roles.flatMap(role => allSkills[role] || []).filter(skill => skill.charAt(0).toLowerCase() === letter.toLowerCase());
        } else if (letter && letter && letter !== "#") {
            skillsArray = [...new Set([
                ...allSkills.Backend,
                ...allSkills.Frontend,
                ...allSkills.BusinessIntelligence,
                ...allSkills.CloudComputing,
                ...allSkills.DataEngineering,
                ...allSkills.DataScience,
                ...allSkills.DevOps,
                ...allSkills.GameDevelopment,
                ...allSkills.MachineLearning,
                ...allSkills.Mobile])
            ].filter(skill => skill.name.charAt(0).toLowerCase() === letter.toLowerCase());
        } else if (roles.length) {
            skillsArray = roles.flatMap(role => allSkills[role] || []);
        } else {
            skillsArray = [...new Set([
                ...allSkills.Backend,
                ...allSkills.Frontend,
                ...allSkills.BusinessIntelligence,
                ...allSkills.CloudComputing,
                ...allSkills.DataEngineering,
                ...allSkills.DataScience,
                ...allSkills.DevOps,
                ...allSkills.GameDevelopment,
                ...allSkills.MachineLearning,
                ...allSkills.Mobile])
            ];
        }

        // Add unique and stable keys to each skill
        skillsArray = skillsArray.map((skill, index) => ({ key: `${skill.name}-${index}`, skill }));
        const sortedSkills = skillsArray.sort((a, b) => a.skill.name.localeCompare(b.skill.name));
        setSkills(sortedSkills);
    }, [roles, letter]);




    return (
        <>
            <Helmet>
                <title>Hire Top Tech Experts And Vetted Resources - Teckable</title>
                <meta name="description" content="Discover vetted resources with 500+ in-demand skills. Hire top tech experts with our comprehensive hiring guide to find the perfect talent for your needs." />
                <meta name="keywords" content="tech expert,top tech experts" />
                <link rel="canonical" href="https://www.teckable.com/skills-explore" />
            </Helmet>
            <Box className="skills">
                <Box>
                    <ResponsiveAppBar />
                </Box>
                <section className='find-developers'>
                    <Container maxWidth="lg" sx={{ py: { md: 10, xs: 7 }, pb: { xs: 2 }, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h1>Discover Vetted Resources with 500+ In-Demand Skills</h1>
                        <p>Find the perfect talent for your needs using our comprehensive hiring guide.</p>
                    </Container>
                </section>
                <Search roles={roles} setRoles={setRoles} skills={skills} letter={letter} setLetter={setLetter} allSkills={allSkills} />
                <SkillsList roles={roles} letter={letter} setRoles={setRoles} skills={skills} allSkills={allSkills} />
                <ContactUs />
                <Footer />
            </Box>


        </>
    )
}

export default Skills