import { Container, Grid } from '@mui/material';
import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// const list = [
//     "Understand your development needs",
//     "Explain our process to match you with qualified, vetted developers from our network",
//     "Share next steps to finding the right match, often within less than a week"
// ];

export const SkillsList = ({ roles, letter, setRoles, skills, allSkills }) => {
    const [isListed, setList] = React.useState(false);

    // Ensure skills is an array of objects with a `skill` property
    const displayedSkills = isListed || roles.length ? skills : skills.slice(0, 10);

    return (
        <>
            <section className='skills-list'>
                <Container maxWidth="lg">
                    <Grid container gap={3} alignItems={"center"} justifyContent={"center"}>
                        {Array.isArray(displayedSkills) && displayedSkills.map((item, index) => (
                            <Grid item xs={12} md={5.5} key={item.key || index} className='skill'>
                                <p
                                    onClick={() => {
                                            window.location.href = item.skill.url;
                                    }}
                                >
                                    {item.skill.name}
                                </p>
                            </Grid>
                        ))}
                    </Grid>
                    {(!roles.length && (letter === "#" || !letter)) && (
                        <p className='arrow' onClick={() => setList(!isListed)}>
                            {isListed ? 'SHOW LESS' : 'SHOW MORE'}
                            {isListed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </p>
                    )}

                </Container>
            </section>

            {/* <section className='contact-form'>
                <Contact
                    title={"Find your next developer within 5 days, not months"}
                    subTitle={"We can help you deliver your product faster with an experienced remote developer. All from €31.90/hour. <span>Only pay if you’re happy with your first week.</span>"}
                    listTitle={"In a short 25-minute call, we would like to:"}
                    list={list}
                />
            </section> */}
        </>
    );
};
